@import "variables";

.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.button__icon-blue {
  fill: #4963fe;
}

.button__icon-green {
  fill: #11c684;
  color: #11c684;
}

.button__icon-red {
  fill: #ef2624;
}

.progress-bar-line .progress-bar-line-survey-top {
  position: initial !important;
}

.rotate-toggle {
  transform: rotate(180deg);
  transition: transform 0.2s;
}

.cursor-pointer {
  cursor: pointer;
}

.modal-header .modal-title {
  font-size: 24px;
}

.card-content .dropdown-menu {
  position: absolute;
  will-change: transform;
  left: 0px;
  top: inherit;
  left: inherit;
}

.button.dropdown-toggle {
  background: none;
  border-color: #4963fe;
}

button .btn-secondary:active {
  background: none;
  border-color: #4963fe;
}

.show > .btn-secondary.dropdown-toggle:focus {
  background: none;
  box-shadow: none;
  border-color: #4963fe;
}

.show > .btn-secondary.dropdown-toggle {
  background: none;
  border-color: #4963fe;
  color: #4963fe;
}

.dropdown .dropdown-toggle::after {
  display: none;
}

.dropdown.show button svg {
  transform: rotate(180deg);
}

.dropdown button svg {
  transition: 0.3s;
}

.graph-container {
  border-radius: 15px;
  overflow: hidden;
  padding-left: 0px !important;
  padding-top: 0px !important;
  padding-right: 0px !important;
  background: #fdfffc !important;
  // left: 0px;
  // right: 0px;
  // top: 0px;
  // bottom: 0px;

  //background-color: white;
}

.graph-container.modal-body {
  padding-bottom: 0px !important;
}

.graph-container .maximize {
  position: absolute;
  right: 40px;
  top: 40px;
  cursor: pointer;
}

.graph-modal {
  width: 90vw; /* Occupy the 90% of the screen width */
  max-width: 90vw;
  height: 90vh;
  max-height: 90vh;
}

.minimize {
  position: absolute;
  right: 40px;
  top: 40px;
  cursor: pointer;
}

.graph-modal-container {
  height: 100%;
}

.modal-content.graph-container {
  padding-bottom: 0px !important;
}

.card__content-wrapper.graph-container {
  border: 1px solid white;
}

.matrixview {
  overflow: auto;
  padding-bottom: 10px;
  margin: auto;
}

.matrixview .background {
  fill: #fdfffc;
}

.matrixview line {
  stroke: #e7e9ee;
}

.matrixrow.selection text {
  fill: #a033ff;
}

.matrixrow.dragging text {
  fill: #25283d;
}

.column.selection text {
  fill: #a033ff;
}

.column.dragging text {
  fill: #25283d;
}

.matrixrow.active text {
  fill: #a033ff;
}

.matrixview text {
  // cursor: pointer;
}

.column.active text {
  fill: #a033ff;
}

.dt-selected {
  background: #ddd;
}

.button.disabled {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.loading-spin {
  height: 100%;
  display: 'flex';
  // text-align: 'center;
  justify-content: 'center';
  align-items: 'center';
  margin: 'auto';
}

@keyframes rotateAroundCenter {
  from {
    transform: rotate(0deg) translate(0px, -100%);
  }
  to {
    transform: rotate(360deg) translate(0px, -100%);
  }
}


pre {
  font-family: "courier new", courier, monospace;
  white-space: pre-wrap;
  font-size: 11px;
}

.draft {
  -webkit-text-stroke: 2px grey;
  color: white;
  font-size: 50px;
  text-align: center;
  margin: auto;
}

.login-button {
  margin: auto;
  left: 0;
  right: 0;
  display: block;
}

.matrixview svg {
  display: block;
  margin: auto;
  user-select: none;
}

// g text{
//   font-family: 'Arial';
// }

.graph-filter {
  position: absolute;
  z-index: 2;
  display: inline-block;
}

.graph-filter p,
.graph-filter h3 {
  text-align: center;
  margin-bottom: 2px;
  margin-top: 4px;
}

.graph-filter .filter-select {
  padding: 5px;
  min-width: 300px;
  max-width: 400px;
}

.statistics {
  min-width: 250px;
  max-width: 600px;
  border-radius: 0.25rem;
  box-shadow: 0 2px 15px 0 rgba(88, 129, 235, 0.4);
  // position: absolute;
  margin: 20px;
  z-index: 2;
  display: inline-block;
  font-size: 1.4rem;
}

.statistics-toggled {
  height: 38px;
  overflow: hidden;
  max-width: 250px;
  animation: bounce 800ms ease-out;
}

.statistics p,
.statistics h3 {
  text-align: center;
  margin-bottom: 2px;
  margin-top: 4px;
}

.matrix-filter p,
.matrix-filter h3 {
  text-align: center;
  margin-bottom: 2px;
  margin-top: 4px;
}

.matrix-filter .filter-select {
  padding: 5px;
  min-width: 300px;
  max-width: 400px;
}

.filter-activities {
  padding: 5px;
  min-width: 300px;
  max-width: 400px;
}

.filter__value-container {
  padding: 0px !important;
  // display: inline-block !important;
}

.filter__multi-value__remove {
  margin-left: auto !important;
}

.closed-survey {
  color: #72bb53;
  font-size: 1.2em;
  text-align: center;
  font-weight: bold;
}

.filter-toggler {
  position: absolute;
  right: 20px;
  top: 10px;
  cursor: pointer;
}

.graph-filter-toggled {
  min-width: 250px !important;
  height: 38px;
  overflow: hidden;
  max-width: 250px;
  animation: bounce 800ms ease-out;
}

.btn-selected {
  background: #6295ff;
  color: #fdfffc;
  border-radius: 20px;
  border: 2px solid #4963fe;
}

.btn-unselected {
  background: #fdfffc;
  color: #4a4a4a;
  border-radius: 20px;
  border: 2px solid #4963fe;
}

.invalid-feedback {
  font-size: 16px;
}

.invalid-feedback::before {
  content: url("../img/icons/danger.svg");
  height: 20px;
  width: 14px;
  display: inline-block;
  margin-right: 10px;
}

input.is-invalid {
  animation: shake 0.5s;
  border: 2px solid purple !important;
}

.dropdown.is-invalid {
  animation: shake 0.5s;
  border: 2px solid purple !important;
}

.edit-cell-activity-selector.is-invalid {
  animation: shake 0.5s;
  border: 2px solid purple !important;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.filter-header input {
  font-size: 24px !important;
  color: #4963fe;
  background: #f7f9ff;
  border: none;
  border-bottom: 2px solid rgba(73, 99, 254, 0.1);
}

.filter-header input:focus {
  color: #4963fe;
}

.filter-header h5 {
  font-size: 24px;
}

.filter-header {
  padding: 16px;
}

.form-header {
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 15px;
}

.form-label {
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 10px;
}

input[type="text"].form-control:disabled {
  background: white;
}

.filter .filter__control {
  // width: 400px;
}

.filter__multi-value {
  width: calc(100% - 10px);

  .filter__multi-value__label {
    white-space: normal;
  }
}

.filter .filter__multi-value {
  background-color: #6295ff !important;
}

.filter .filter__multi-value div {
  color: white;
}

.item-filter__multi-value {
  background-color: #6295ff !important;
}

.item-filter__multi-value div {
  color: white;
}

.rc-slider-handle {
  width: 20px !important;
  height: 20px !important;
  margin-top: -8px !important;
}

.fitness-graph {
  background: white !important;
}

.fitness-inside {
  flex-flow: column;
  display: flex;
  height: 100%;
  padding: 30px;
}

.button {
  height: 43px;
  white-space: nowrap;

  .material-symbols-outlined {
    font-variation-settings:
    'FILL' 0,
    'wght' 300,
    'GRAD' 0,
    'opsz' 26;
    font-size: 26px;
    vertical-align: middle;
    padding-left: 8px;
    overflow: visible;
    transform: translateY(-2px);
  }

  svg {
    overflow: visible;
  }
}

.collapse-container {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}

.container-wide-collapse-button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 0;
  padding: 5px 10px;
  border: $light-border;
  background-color: $background;
  border-color: $light-border;
  color: $paragraph;
  height: 43px;
  cursor: pointer;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.container-wide-collapse-button:hover {
  background-color: $button-fill; /* Hover state */
  color: white;
}

.collapse-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  padding: 0 15px;
  background-color: #f9f9f9;
  margin-top: 10px;
  border-radius: 5px;
}

.container-wide-collapse-button:hover {
  box-shadow: 0 2px 15px 0 rgba(88, 129, 235, 0.4);
}

.evaluation-card {
  min-height: 100px;
  height: auto;
  padding: 16px;
  box-sizing: border-box;
  overflow: auto;

  p {
    max-width: 100%;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
}

.error-border {
  border: 1px solid red;
  border-radius: 5px;
}

.analysis.maximize {
  position: absolute;
  right: 15px;
  top: -15px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.dropdown {
  position: relative;

  svg {
    top: 0;
    bottom: 0;
    margin: auto;
    right: 10px;
    position: absolute;
  }

  p {
    text-align: left;
  }
}

.dropdown {
  p svg {
    transform: rotate(0deg);
    transition: transform 0.2s;
  }

  p.show svg {
    transform: rotate(180deg);
    transition: transform 0.2s;
  }
}

.dropdown.dd-filter {
  width: 340px;
  margin-left: 30px;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  transition: all 100ms;
  box-sizing: border-box;
  padding-left: 10px;
}

.button.disabled::before {
  background: none;
}

.analysis-management-actions .button {
  width: 150px;
}

.analysis-management-actions {
  position: relative;
}

.design-management-actions {
  max-width: 150px;
  margin: 0 auto;
}

.design-managment-buttons .button {
  width: 110px;
  padding: 8px 0;
  margin: 0 5px;
}

.design-managment-buttons {
  display: flex;
  max-width: 400px;
  margin: auto;
  left: 0;
  right: 0;
}

.sidebar-item-link {
  width: 100%;
  padding: 14px 30px;
}

.design_content.draft {
  height: 228px;
  line-height: 200px;
}

// .filter__dropdown-indicator{
//   display: none !important;
// }

// .filter__indicator-separator{
//   display: none !important;
// }

.filter__input {
  width: 0px !important;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.survey_card {
  height: 400px;
  background: #fff !important;
}

.survey_card-wrapper .survey_card:hover {
  box-shadow: 0 8px 15px 0 rgba(88, 129, 235, 0.6);
}

.analysis_card-wrapper .card__content-wrapper:hover {
  box-shadow: 0 8px 15px 0 rgba(88, 129, 235, 0.6);
}

.progressbar {
  flex: 0 1 auto;
}

.tooltip {
  padding: 0;
}

.tooltip__inner {
  opacity: 1;
  background: #f7f9ff;
  color: #25283d;
  border-radius: 5px;
  box-shadow: 0 2px 15px 0 rgba(88, 129, 235, 0.4);
  padding: 3px;
  position: absolute;
  width: max-content;
  display: inline-block;
  max-width: 400px;
  overflow: hidden;
  pointer-events: none;
}

rect.highlight {
  fill: #a033ff;
  pointer-events: none;
}

rect.selection {
  fill: #a033ff;
  pointer-events: none;
}

rect.dragging {
  fill: #25283d;
  pointer-events: none;
}

.matrixview .axis-rect {
  // cursor: pointer;
}

.editcellmodal .row {
  margin: 1em 0 !important;
}

.editcellmodal p {
  margin: 4px !important;
}

.editcellmodal .dropdown {
  background: white;
  border: 1px solid;
  border-radius: 7px;
  border-color: rgba(73, 99, 254, 0.1);
  height: fit-content;
  margin: auto;
}

.dataTable .invalid-feedback {
  font-size: 0.7em;
}

.dataTable td div {
  width: 100%;
  height: 100%;
}

.pagination {
  display: block;
}

.btn:disabled {
  opacity: 1;
}

.btn.normal:disabled {
  opacity: 0.65;
}

.dataTable .form-group {
  margin-bottom: 0;
}

.dataTable th .form-group {
  vertical-align: top;
}

.draft-html-editor-wrapper {
  height: 100%;
  min-height: 500px;
  border: 1px solid #ddd;
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 2em;
  box-shadow: inset 0px 1px 8px -3px $lightText;
  background: $background;
  position: relative;
}

.draft-html-editor {
  height: 100%;
  min-height: 475px;
  overflow-y: auto;
  cursor: text;
  padding: 10px;
  margin-bottom: 0;
  background: $vsBackground;
}

.draft-html-editor-wrapper .rdw-editor-main {
  height: 400px !important; /* Ensure editor height is respected */
  overflow-y: auto !important; /* Enable scrolling */
  padding: 10px;
  background-color: $vsBackground;
}

.html-editor {
  box-sizing: border-box;
  border: 1px solid #ddd;
  cursor: text;
  padding: 16px;
  border-radius: 10px;
  margin-bottom: 2em;
  box-shadow: inset 0px 1px 8px -3px #ababab;
  background: #fefefe;
}

.email-editor {
  margin-top: 20px;

  .form-group {
    display: flex;
  }

  label {
    text-align: left;
    width: 150px;
  }
}

.card__sub_header {
  border-bottom: 2px solid rgba(73, 99, 254, 0.1);
}

.coord-load {
  position: absolute;
  bottom: 40px;
  left: 0;
}

.groups-wrapper {
  display: flex;
  box-pack: justify;
  flex-flow: row wrap;
  background: #FFF;
  border-radius: 10px;
  padding: 10px;
  margin-right: 10px;
  box-shadow: 0 2px 15px 0 rgba(88, 129, 235, 0.4);
  width: 100%;
}

.card-item {
  width: 92px;
  background: white;
  border: 2px solid var(--itemColor);
  border-radius: 5px;
  display: inline-block;
  margin: 5px;
  vertical-align: top;
  position: relative;

  &.choosen {
    background: rgba(160, 51, 255, 0.5);
    box-shadow: 0px 0px 3px 3px #888888;
  }

  &.incomming {
    background: rgba(52, 227, 159, 0.5);
  }

  &.outgoing {
    background: rgba(52, 227, 159, 0.5);
  }

  &.both {
    background: rgba(52, 227, 159, 0.5);
  }

  .card-name {
    width: 100%;
    min-height: 88px;
    height: max-content;
    padding: 2px;
    margin: auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    cursor: pointer;
    white-space: normal;
    text-align: center;
    pointer-events: none;
    flex-direction: column;
    top: 0;
    bottom: 0;
    position: absolute;

    p {
      margin-bottom: 10px;
      line-height: 1;
      word-break: break-word;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.items-row {
  height: 110px;
  white-space: nowrap;
  overflow: hidden;
}

.ghost-item {
  opacity: 0.2;
}

.drag-item {
  opacity: 1 !important;
}

.ghost-group {
  opacity: 0.2;
}

.drag-group {
  opacity: 1 !important;
}

.design-label-selector button {
  padding: 3px 10px;
}

.fl-scrolls {
  &::-webkit-scrollbar {
    width: 8px;
    height: 15px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
}

.card .card__content-wrapper .card__header {
  input {
    line-height: 30px;
    margin: 0;
    padding: 4px 10px;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 600;
    background-color: #fdfffc;

    &:read-only {
      border: none;
    }
  }

  button {
    margin-left: 10px;
  }
}

.dataTable {
  .remove_column {
    z-index: 999;
    display: none;
    position: absolute;
    width: 80px;
    height: 30px;
    text-align: center;
    left: 10px;
    top: 50px;
    line-height: 10px;
    line-height: 30px;
    color: red;
    background: white;
    border: 1px solid rgba(73, 99, 254, 0.1);
    box-shadow: 0 2px 15px 0 rgba(88, 129, 235, 0.4);
  }

  th {
    position: relative;
  }

  th:hover {
    .remove_column {
      display: block;
    }
  }

  input:read-only {
    border: none;
    background: transparent;
    cursor: pointer;
    font-weight: bold;
  }
}

.hover-highlight {
  pointer-events: none;
}

.ag-header {
  background-color: #6295ff !important;
  border-bottom-style: solid !important;
  border-bottom-width: 2px !important;
  border-bottom-color: #f8f8f8 !important;
}

.ag-header-cell-center {
  padding: 0 !important;
  margin: 0 !important;

  .ag-header-cell-label {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.ag-cell-total {
  font-weight: bold;
  text-align: center;
}

.ag-cell-center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0 !important;
  margin: 0 !important;
  text-align: center !important;
}

.ag-header-row {
  font-weight: 400 !important;
  font-size: 14px;
}

.ag-cell-prevent-word-split {
  white-space: normal;
  word-wrap: break-word;
  word-break: keep-all;
  overflow-wrap: break-word;
}

.ag-cell {
  display: flex !important;
  align-items: center !important;
  justify-content: start;
  overflow: visible !important;
  line-height: 2.5 !important;

  .ag-cell-center {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 0 !important;
    margin: 0 !important;
    text-align: center !important;
  }

  .ag-cell-left {
    display: flex !important;
    justify-content: start !important;
    align-items: center !important;
    padding: 0 !important;
    margin: 0 !important;
    text-align: center !important;
  }
}

.ag-row {
  overflow: visible !important;
}

.cell-wrapper {
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}


.filter-modal-body {
  height: calc(100% - 150px);

  ul {
    margin: 0;
  }

  li {
    cursor: pointer;
  }

  .filter-label-group {
    margin-top: 20px;
  }

  .flag {
    cursor: pointer;
    border-radius: 20px;
  }

  .list-group {
    overflow-y: auto;
    flex: 1;

    &.activities {
      padding: 10px;

      .collpase-row-header {
        border: none;
      }
    }
  }

  .side-content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.filter-header {
  .add-filter-btn {
    margin-top: 15px;
    cursor: pointer;
  }
}

.header-term {
  padding: 0 5px;
  padding-right: 40px;
  font-size: 13px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  max-width: 200px;

  &:hover {
    background: #f7f9ff;
  }

  .header-term-label {
  }

  .header-term-value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  .header-term-del {
    position: absolute;
    right: 5px;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    text-align: center;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }
}

.progress-bar-donut {
  width: max-content;
}

.circular-progressbar {
  width: 180px;
  height: 180px;
  background: none;
  margin-bottom: 45px;
  margin-top: 30px;
  position: relative;

  svg {
    height: 180px;
    width: 180px;
    fill: none;
    stroke: red;
    stroke-width: 10;
    stroke-linecap: round;
    -webkit-filter: drop-shadow(-3px -2px 10px rgba(98, 149, 255, 0.2));
    filter: drop-shadow(-3px -2px 10px rgba(98, 149, 255, 0.2));
  }

  .progressbar-text {
    color: #4a4a4a;
    font-size: 48px;
    line-height: 1;
    font-weight: bold;
    text-align: center;
    letter-spacing: -0.05em;

    span {
      font-size: 32px;
      margin-left: 5px;
    }
  }

  .progress-bar-title {
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    margin-bottom: 0;
    letter-spacing: normal;
  }
}

.card-gauge-chart {
  position: relative;
}

.dropdown .modal-dropdown {
  max-height: 190px;
  overflow-y: auto;
}

@keyframes gemini {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes gemini-slide {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.agent-card {
  border-radius: 15px;
  border: 4px solid transparent;
  border-color: rgba(17, 198, 132, 0.6); /* Adjust the opacity (0.5 for 50% opacity) */
  // border-color: #11C684;
  // border-image: radial-gradient(circle, #11c684, #4963fe) fill 1;
  // animation: gemini-slide 10s linear infinite;
  // -webkit-mask-image: -webkit-radial-gradient(white, black);
  // mask-image: radial-gradient(white, black);
  // border-image-slice: 30%;
  margin: 10px;  
}

.agent-card-selected {
  //background: linear-gradient(45deg, #11c684, #4963fe);
  animation: gemini-slide 120s linear infinite alternate forwards;
  // background-image: linear-gradient(45deg, #11c684, #4963fe);
  background-image: linear-gradient(45deg, rgba(17, 198, 132, 0.6), rgba(17, 198, 132, 0.6) 35%, rgba(73, 99, 254, 0.6), rgba(17, 198, 132, 0.6) 65%, rgba(17, 198, 132, 0.6));
  background-size: 1000% 100%;
}

.employee-card {
  border-radius: 15px;
  border: 4px solid;
  border-color: rgba(74, 74, 74, 0.6);
  margin: 10px;
}

.agent-team-card {
  display: flex;
  flex-wrap: wrap;
  max-height: '80vh';
  overflow-y: auto;
  padding-left: 20px;
  margin-right: 15px;
  margin-left: 15px;
  border-radius: 15px;
  border: 4px solid #4a4a4a;
  max-height: '80vh';
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  border-radius: 15px;
  border: 4px solid #4a4a4a;
  margin: 10px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}


.center_content {
  justify-content: center;
  align-items: center;
  margin: auto;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
  margin-left: 0;
  margin-right: 0;
}

.full-width-row {
  display: flex;
  justify-content: center;
  width: 100%;
  
  margin-left: 0;
  margin-right: 0;
}

.container-wide-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 95%;
  min-width: 400px;
  margin-left: 0;
  margin-right: 0;
  margin-top: 20px;
}

.input-with-label {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-right: 20px;
}

.gen-ai-tabs {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .tab-content {
    margin: 0;
    background: #FFF;
    padding: 1rem;
    border: 1px solid #EEE;
    border-top: none;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  .maximize-btn {
    position: absolute;
    right: 15px;
    top: 5px;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .color-options {
    max-height: 150px;
    overflow-y: auto;
  }
}

.employee-card-row-wrapper {
  height: 60vh;
  overflow-y: auto;
  padding: 20px;
  margin-right: 15px;
  margin-left: 15px;
  border-radius: 15px;
  border: 1px solid #ddd;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.metric-card {
  height: 60vh;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.metric-card-body h3 {
  font-size: 16px;
  margin: 10px 0;
  display: flex;
  align-items: center;
}

.metric-card-footer {
  text-align: right;
  padding-top: 20px;
}

.metric-icon {
  font-size: 24px;
  margin-right: 10px;
}

.metric-card-header {
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 20px;

  h3 .bold{
    font-weight: 600;
  }
}

.metrics-column {
  float: left;
  text-align: left;
  border-left: 2px solid #ccc; 
  padding-left: 20px;
}

.gradient-animated-text {
    background: radial-gradient(circle, #11c684, #4963fe);
    animation: gemini 10s linear infinite;
    background-size: 200% 200%;
    background-clip: text;
    color: transparent;
    //animation: blink 2s linear infinite;
    //border: 2px solid transparent;
}

.grid-height-400 {
  min-height: 400px;
  height: 83%;
  width: 100%;
  min-height: 20vh;
  min-width:200px;
  overflow: auto;
}

.grid-height-800 {
  height: max-content;
  width: 100%;
  min-height: fit-content;
  min-width:200px;
  overflow: auto;
  padding-bottom: 20px;
}

.card-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10;
}

.ag-theme-alpine {

  .ag-header {
    // or write CSS selectors to make customisations beyond what the parameters support
    background: transparent !important;
    border: none;

    .ag-header-row {
      font-weight: bold !important;
    }

    .ag-header-cell {
      .remove_column {
        z-index: 999;
        display: none;
        position: fixed;
        text-align: center;
        right: 2px;
        top: 10px;
        color: red;
        background: white;
      }

      input {
        background: #fff;
        border: none;
      }

      &:hover {
        .remove_column {
          display: block;
        }
      }
    }

    .ag-grid-custom-header {
      transform: translate3d(0, 0, 0);

      input {
        font-weight: bold;
      }
    }  

    .ag-cell-error {
      background-color: red !important;
      border: 2px solid purple !important;
      color: white !important;
    }
    
    @keyframes shake {
      0%, 100% { transform: translateX(0); }
      25%, 75% { transform: translateX(-5px); }
      50% { transform: translateX(5px); }
    }
    
    .ag-cell-shaking {
      animation: shake 0.5s ease-in-out;
    }
  }

  .ag-root-wrapper {
    border: none !important;
  }

  .grid-button {
    border: none;
    outline: none;
    background: transparent;
  }

  .ag-row {
    .grid-button {
      display: none;
    }

    &.ag-row-hover {
      .grid-button {
        display: block;
      }
    }
  }

  /* Ensure cells expand to fit content */
  .ag-cell {
    overflow: hidden !important;
  }

  /* Optional: Adjust the cell padding and alignment */
  .ag-cell {
    padding: 0 !important;
    display: flex;
    align-items: stretch;
  }

  /* Style for the draggable cards */
  .draggable-card {
    user-select: none;
    padding: 8px;
    margin: 0 0 4px 0;
    min-height: 40px;
    background-color: #f9f9f9;
    color: black;
    border: 1px solid #ddd;
    border-radius: 4px;
    display: flex;
    align-items: center;
  }

}

.filter-activities-content {
  border-radius: 15px;
  background-color: #fdfffc;
  -webkit-box-shadow: 0 2px 15px 0 rgba(98, 149, 255, 0.2);
  box-shadow: 0 2px 15px 0 rgba(98, 149, 255, 0.2);
}

.filter-activities-list {
  overflow-y: hidden;
  max-height: calc(100vh - 375px);
  padding: 3px;
  position: relative;
  display: flex;
  flex-direction: column;

  .activity-selector {
    border: none;

    .list-group {
      overflow-y: auto;
      flex: 1;

      &.activities {
        .collpase-row-header {
          border: none;
        }
      }
    }
  }
}

.collapse-wrapper {
  width: 100%;

  &:nth-child(even) {
    background: rgba(98, 149, 255, 0.1);
  }
}

.collpase-row-header {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 24px;

  .collpase-row-header-content {
    flex: 1;

    label {
      max-width: 370px;
    }
  }

  .collpase-row-button {
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 4px;
    cursor: pointer;
  }
}

.check-row {
  max-width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
}

.matrix-toolbar {
  position: fixed;
  right: 0;
  top: 50%;
  background: #fff;
  border-radius: 10px 0px 0px 10px;
  transform: translateY(-50%);
  padding: 10px;
  box-shadow: 0 2px 15px 0 rgba(88, 129, 235, 0.4);
}

.matrix-toolbar-button {
  width: 30px;
  height: 30px;
  padding: 3px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: rgba(88, 129, 235, 0.1);
  }

  &.active {
    background: rgba(88, 88, 88, 0.3);
  }
}

.matrix-toolbar-button + .matrix-toolbar-button {
  margin-top: 10px;
}

.graph-container {
  .nav-item {
    cursor: pointer;
  }
}

.editcellmodal.readonly {
  .dropdown {
    background: transparent;
    border: none;
  }

  .filter__control {
    background: transparent;
    border: none;

    .filter__indicators {
      display: none;
    }

    .filter__multi-value__remove {
      display: none;
    }

    .filter__multi-value {
      background-color: transparent !important;

      div {
        color: #4a4a4a;
      }
    }
  }
}

.ag-row .delete-row-button-cell .delete-row-button {
  visibility: hidden;
}

.ag-row:hover .delete-row-button-cell .delete-row-button {
  visibility: visible;
  box-shadow: 0 2px 15px 0 rgba(88, 129, 235, 0.4);
}

.ag-row .add-row-button-cell .add-row-button {
  visibility: hidden;
  color: $green;
}

.ag-row:hover .add-row-button-cell .add-row-button {
  visibility: visible;
}


.ag-theme-alpine.table-bar .ag-cell {
  padding-left: 2px;
  padding-right: 2px;
  display: inline-block;
}

.matrix-filter-modal {
  height: calc(100vh - 100px);
  min-height: 400px;
}

.validation-error {
  background-color: #f9d6d5;
  color: #842029;
}

.edit-cell-activity-selector {
  overflow-y: hidden;
  max-height: calc(100vh - 375px);
  overflow-x: hidden;
  padding: 3px;

  .activity-selector {
    .list-group {
      overflow-y: auto;
      flex: 1;

      &.activities {
        .collpase-row-header {
          border: none;
        }
      }
    }
  }
}

.tooltip-activityname {
  margin-left: 8px;
}

.w-max-content {
  width: max-content;
}

.tooltip__inner {
  .form-check {
    min-height: 0;
    margin: 0;
    padding: 0;
  }

  .form-check-label {
    font-size: 12px;
    display: inherit;
  }

  .collpase-row-button {
    display: none;
  }

  .check-row {
    padding: 0;
  }
}

.activity-all-check {
  flex-shrink: 0;
}

.activity-selector {
  overflow-y: hidden;
  flex: 1;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 20px);

  .activities-search-input {
    border-radius: 20px;
    border: 2px solid #4963fe;
    padding: 12px 15px;
  }

  .activities {
    padding: 10px;
  }
}

.side-content .activity-selector {
  margin-top: 10px;
}

.graph-view {
  width: 100%;
  height: calc(100vh - 330px);
  min-height: calc(100vh - 330px);
}

.strategy-index {
  .strategy-tab {
    cursor: pointer;
  }

  ul {
    margin: 0;
  }

  .current-button {
    width: 100%;
    background: rgba(160, 51, 255, 0.4);
    color: #000;

    &.current {
      background: rgba(73, 99, 254, 0.4);
    }
  }
}

.strategy-activities-table {
  height: calc(100vh - 400px);

  .name-cell {
    color: #000;
  }

  .current {
    background: rgba(73, 99, 254, 0.4);
  }

  .new {
    background: rgba(160, 51, 255, 0.4);
  }
}

.strategy-functions {
  .function-name-cell {
    font-weight: bold;
    background: #f4f5f7;
    border-right: 2px solid #ababab !important;
  }

  .ag-header-cell {
    background: #f4f5f7;
  }

  .ag-header {
    border-bottom: 1px solid #ababab !important;
  }

  & .ag-header-cell:not(:last-child), & .ag-cell:not(:last-child) {
    border-right: 1px solid #ababab !important;
  }

  .current-button {
    border: 0;
    border-radius: 5px;
  }
}

.tableFixHead {
  overflow: auto;

  table {
    max-height: 100%;
  }

  thead th {
    position: sticky;
    top: 0;
    z-index: 1;
    background: #FFF;
  }
}


.strategy-responsibilites-table {
  .grid-button {
    border: none;
    outline: none;
    background: transparent;
  }

  table {
    border-collapse: separate;
    border-spacing: 0;
    margin-top: 10px;

  }

  th {
    position: relative;
    min-width: 110px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-left: 1px solid #f5f5f5;
    border-right: 1px solid #f5f5f5;
    border-top: 1px solid #f5f5f5;
    font-size: 13px;
    padding: 4px;

    &.unit-header {
      border-bottom: 1px solid #f5f5f5;
    }

    &.last-row {
      border: none;
      border-bottom: 2px solid;
      padding: 0;
      border-image: linear-gradient(90deg, #4963FE 0%, #11C684 100%) 1;
    }

    .remove_column {
      z-index: 999;
      display: none;
      position: absolute;
      text-align: center;
      right: 2px;
      top: 5px;
      color: red;
      background: white;
      cursor: pointer;
    }

    .editable-text {
      padding: 0;
      margin: 0;
      border: none;
      outline: none;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: bold;
      width: 150px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      [contenteditable] {
        outline: 0px solid transparent;
      }
    }
  }

  th:hover {
    .remove_column {
      display: block;
    }
  }

  td {
    cursor: pointer;
    border-left: 1px solid #f5f5f5;
    border-right: 1px solid #f5f5f5;
    font-size: 13px;
    padding: 4px;

    &.responsible {
      position: relative;
      vertical-align: middle;

      .responsible-inner {
        width: 80%;
        height: 30px;
        background: #6295FF;
        border-radius: 10px;
        margin: auto;
        box-shadow: 0 2px 15px 0 rgba(88, 129, 235, 0.8);
      }

      font-weight: bold;
    }
  }

  button {
    box-shadow: none !important;
  }

  td.sticky-column, th.sticky-column {
    position: sticky;
    background: #FFF;
    width: 110px;
    z-index: 2;

    &:first-child {
      left: 0;
    }

    &:nth-child(2) {
      left: 110px;
    }
  }


}

.design-item-edit-modal {
  max-height: calc(100vh - 100px);

  .activity-selector {
    max-height: calc(100vh - 250px);

    .list-group {
      overflow-y: auto;
    }
  }

  h3 {
    width: fit-content;
    align-items: center;

    span.indicator {
      margin-left: 20px;
    }
  }
}

.design-navs {
  margin-left: 0;
  position: relative;

  .design-nav {
    cursor: pointer;
  }
}

.design-controls {
  width: 400px;
  border-radius: 10px;
  background: #FFF;
  padding: 10px 5px;
  box-shadow: 0 2px 15px 0 rgba(88, 129, 235, 0.4);
  position: sticky;
  top: 100px;
  height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;

  .unassinged-items {
    overflow-y: auto;
    height: 100%;

    & > div {
      height: 100%;
    }
  }

  .button {
    padding: 5px 10px;
  }

  .group {
    display: flex;
  }
}

.strategy-groupby {
  .btn {
    padding: 5px 10px;
    font-size: 12px;
  }

  h4 {
    margin: 0;
    margin-right: 10px;
  }
}

.font_24 {
  font-size: 24px;
}

.design-strategy {
  button.btn {
    padding: 5px 10px;
    font-size: 12px;
  }

  .legends {
    font-size: 12px;

    .strategy-legend {
      width: 45px;
      border-radius: 10px;
      margin-right: 10px;

      &.responsible {
        background: rgba(52, 227, 159);
      }

      &.unit {
        background: rgba(73, 99, 254);
        color: #FFF;
        text-align: right;
        padding: 0 5px;
      }
    }
  }
}

.design-matrixview {
  button.btn {
    padding: 5px 10px;
    font-size: 12px;
  }
}

.graph-view {
  button.btn {
    padding: 5px 10px;
    font-size: 12px;
  }
}


.design-label-selector {
  button.btn {
    padding: 5px 10px;
    font-size: 12px;
  }
}


.design-statistics {
  button.btn {
    padding: 5px 10px;
    font-size: 12px;
  }
}

.design-matrixview {
  background: #FFF;
}

.design-label-edit-modal {
  width: 600px;
  max-width: fit-content;
}

.DropdownItem {
  margin-left: 0 !important;
  padding: 10px 15px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    svg {
      fill: white;
    }
  }

  span {
    flex: 1;
    text-align: center;
  }
}

.design-org {
  display: flex;
  flex-direction: column;
  height: 100%;

  .tab-content {
    margin: 0;
    background: #FFF;
    padding: 1rem;
    border: 1px solid #EEE;
    border-top: none;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  .maximize-btn {
    position: absolute;
    right: 15px;
    top: 5px;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .color-options {
    max-height: 150px;
    overflow-y: auto;
  }
}


#portal {
  z-index: 10000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #f7f9ff;

  .design-org {
    height: 100%;
    min-height: 100vh;

    .graph-view {
      min-height: calc(100vh - 80px);
    }

    .tab-content {
      height: calc(100vh - 45px);
      overflow-y: auto;
    }

    .graph-view {
      overflow: hidden;
    }
  }

  .design-controls {
    top: 8px;
  }
}

.strategy-index {
  .tab-content {
    padding-top: 10px;
  }
}

.diagnose-index {
  .diagnose-table-wrapper {
    border: 1px solid rgba(73, 99, 254, 0.3);
    border-radius: 10px;
    overflow: hidden;
  }

  .diagnose-table {
    background: #fff;
    color: #4472c4;
    border-spacing: 0;
    border-style: hidden;
    margin: 0;

    tr:first-child td:first-child {
      border-top-left-radius: 8px;
    }

    tr:first-child td:last-child {
      border-top-right-radius: 8px;
    }

    th {
      text-align: center;
      border: 1px solid #4472c4;
      padding: 10px;
    }

    td {
      border: 1px solid rgba(73, 99, 254, 0.3);
      padding: 10px;
      vertical-align: top;
    }

    .header-small {
      font-size: 14px;
    }

    .header-large {
      font-size: 20px;
    }

    .body-caption {
      font-size: 12px;
      font-weight: bold;
    }

    .body-head {
      font-size: 18px;
      font-weight: bold;
    }

    .body-content {
      font-size: 15px;
    }

    .diagnose-table-revert {
      background: #4472c4;
      color: #FFF;
    }

    .diagnose-table-red {
      background: rgba(160, 51, 255, 0.1);
    }

    .diagnose-table-green {
      background: rgba(52, 227, 159, 0.05);

    }

    .diagnose-table-blue {
      background: rgba(73, 99, 254, 0.05);
    }
  }

  .diagnose-table-title {
    font-size: 20px;
  }

}

#statistics-legend-container {
  span {
    border-radius: 10px;
  }

  p {
    font-size: 12px;
  }
}

.matrix-filter {
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 10px;

  .filter__dropdown-indicator, .filter__indicator-separator {
    display: none;
  }

  .filter__input-container {
    width: 0;
  }

  .efficiency {
    padding: 2px 8px;
  }
}


.collapse-row-icon {
  transition: 0.3s transform;

  &.revert {
    transform: rotate(180deg);
  }
}

.define-tab {
  height: calc(75vh);
  overflow: auto;
  display: flex;
  flex-direction: column;

  .row {
    margin: 0 !important;
    overflow: auto;
  }

  .function-tab-header {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 30px;

  }

  button {
    margin-right: 10px;
    margin-bottom: 20px;
  }

  .function-tab-row {
    border: 1px solid rgba(73, 99, 254, 0.05);

    .button__icon {
      display: none;
    }

    &:hover {
      background: rgba(73, 99, 254, 0.05);

      .button__icon {
        display: block;
      }
    }
  }
}

.admin-tab {
  min-height: calc(20%);
  overflow: visible;

  .function-tab-header {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 30px;
  }

  .function-tab-row {
    border: 1px solid rgba(73, 99, 254, 0.05);

    .button__icon {
      display: none;
    }

    &:hover {
      background: rgba(73, 99, 254, 0.05);

      .button__icon {
        display: block;
      }
    }
  }
}

.function-tab {
  height: calc(75vh);
  overflow: auto;

  .function-tab-header {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 30px;
  }

  button {
    margin-right: 10px;
    margin-bottom: 20px;
  }

  .function-tab-row {
    border: 1px solid rgba(73, 99, 254, 0.05);

    .button__icon {
      display: none;
    }

    &:hover {
      background: rgba(73, 99, 254, 0.05);

      .button__icon {
        display: block;
      }
    }
  }
}


.time-util-selector-value-input {
  input {
    padding: 0 5px;
    width: 60px;
    margin-right: 2px;
  }

  float: right;
}

.popover {
  width: fit-content;
  max-width: 1000px;
  border-radius: 25px;
  padding: 10px;
  font-size: medium;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.FloatingTooltip {
  width: 100%;
  border-radius: 20px;
  padding: 10px;
  background-color: rgba(248,253,251, 1);
  z-index: 1000;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);

  .tooltip-inner {
    width: 100%;
    background-color: rgba(248,253,251, 1);
    color: #000;
    font-size: medium;
  }
}

.automation-tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 50px;

  div {
    margin-bottom: 20px;
  }

  .automation-tab-header {
    display: flex;
    justify-content: center;
    width: 'auto';
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .dropdown-toggle {
    background-color: #f8fbfd;
    border: 2px solid #e5e5e5;
    color: #25283d;
    font-size: x-large;
    font-weight: bold;
    border-radius: 15px;
  }

  h2 {
    margin-bottom: 30px; /* More space below headings */
  }

  .row {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
  }

  .automation-suggestion-card {
    padding: 30px;
    width: 100px;
  }

  @keyframes suckIntoScreen {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(0) translate(-50%, -50%);
      opacity: 0;
      top: 50%;
      left: 50%;
    }
  }
  
  .sucking-animation {
    position: relative;
    animation: suckIntoScreen 0.5s forwards; /* Adjust duration as needed */
  }

  .recommendation-item {
    position: relative;
    display: table;
    justify-content: space-between;
    align-items: center;
    background-color: #f8fbfd;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    padding: 60px;
    margin-bottom: 50px;
    width: 100%;

    .delete-suggestion-button {
      position: absolute;
      top: 0;
      right: 0;
      background: none;
      border: none;
      font-size: 32px;
      cursor: pointer;
      padding: 10px 20px;
      opacity: 0.6;
    }

    .description {
      display: contents;
      width: 100%;
      padding-bottom: 150px;
    }

    .recommendation-details {
      margin-top: 50px;
    }

    .complexity {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      width: 40%;
      border-radius: 25px;
    }
  
    .icon {
      overflow: visible;
      display: inline;
      font-size: 46px; 
      text-shadow:
            0 0 1px #4963fe, 
            -2px -2px 2px #4963fe, 
            -1px 1px 2px #4963fe, 
            1px 1px 2px #4963fe, 
            1px -1px 2px #4963fe;
      opacity: 0.8;
      z-index: 0;
    }

    .icon-outline {
      overflow: hidden;
      position: absolute;
      bottom: -75px;
      right: -75px;
      font-size: 250px; 
      color: transparent;
      //color: rgba(100%, 0%, 0%, 0);
      //text-shadow: 0 0 0 #4963fe;
      text-shadow:
            0 0 1px #4963fe, 
            -1px -1px 1px #4963fe, 
            -1px 1px 1px #4963fe, 
            1px 1px 1px #4963fe, 
            1px -1px 1px #4963fe;
      opacity: 0.8;
      z-index: 0;
    }

    .icon-outline[title]:before {
      content: attr(title);
      position: absolute;
      width: 100%;
      text-shadow: 0 0 0 #fff;
    }
  }

  .recommendation-item div.left {
    float: left;
    width: 59%;
    z-index: 1;
  }

  .recommendation-item div.right {
    float: right;
    width: 39%;
    z-index: 1;
  }

  .kpi-card {
    margin: 10px;
    flex-basis: calc(33% - 40px); /* Adjust width for a 3-column layout with spacing */
    margin-bottom: 60px;
    border-radius: 25px;

    .kpi-icon {
      text-shadow:
            0 0 1px #4963fe, 
            -1px -1px 1px #4963fe, 
            -1px 1px 1px #4963fe, 
            1px 1px 1px #4963fe, 
            1px -1px 1px #4963fe;
      opacity: 0.8;
    }

  }

  .kpi-label {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .kpi-label-title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 5px;
  }

}

.popup-warning-icon {
  position: relative;
  top: 50%;
  font-size: 36px;
  font-weight: 800;
  padding: 10px;
  color: #4963fe;
}

.popup-warning-header {
  position: relative;
  top: 50%;
  font-size: 24px;
  font-weight: 600;
  padding: 16px;
}

.popup-warning-message {
  position: relative;
  font-size: 22px;
  font-weight: 500;
  padding: 16px;
}

.context-menu {
  position: fixed;
  padding: 10px;
  background: #333333;
  border-radius: 4px;
  box-shadow: 0 0 4px 2px #33333370;
  color: #FFF;
  fill: #FFF;
  z-index: 100000;

  .context-menu-item {
    justify-content: space-between;
    cursor: context-menu;
    padding: 4px;
    border-radius: 3px;

    &:hover {
      background: #111111;
    }
  }
}

.context-menu-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
}

.activity-table-context-menu {
  padding: 10px;
  background-color: #fdfffc;
  //opacity: 1;
  border-radius: 25px;
  //backdrop-filter: blur(20px); 
  //-webkit-backdrop-filter: blur(20px); /* For Safari */
  box-shadow: '0 10px 15px rgba(0,0,0,0.15)';
  padding: '10px';
}

.context-menu-item {
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out; /* Smooth transition for background color */
}

.context-menu-item:hover {
  background-color: blue; /* Change to your preferred hover color */
  color: white; /* Change text color to ensure readability */
}

.import-survey-department-select {
  width: 200px;
}

.import-survey-department-select.is-invalid {
  animation: shake 0.5s;
  border: 2px solid purple !important;
}

.row-text-red .ag-cell {
  color: red;
}

.invalid_activity-header {
  color: #ff0066;
  font-weight: bold;
}

.invalid_function-header {
  color: #ffcc99;
  font-weight: bold;
}


.double_activity-header {
  color: #ff66cc;
  font-weight: bold;
}

.error-list {
  list-style: none;
}

.error-list li::before {
  content: "";
  display: inline-block;
  width: 0.8em;
  height: 0.8em;
  margin-right: 0.5em;
  border-radius: 0.2em;
}

.error-list li.doubleErr::before {
  background-color: #990033;
}

.error-list li.invalidResErr::before {
  background-color: #cc6600;
}

.error-list li.invalidFunErr::before {
  background-color: #ffcc99;
}

.error-list li.invalidActErr::before {
  background-color: #ff0066;
}

.error-list li.doubleActErr::before {
  background-color: #ff66cc;
}

.error-list li.invalidDataErr::before {
  background-color: #ff0000;
}

.error-list li.fromErr::before {
  background-color: #ff0080;
}

.error-list li.toErr::before {
  background-color: #ff4000;
}

.error-list li.importanceErr::before {
  background-color: #ff8000;
}

.error-list li.stabilityErr::before {
  background-color: #ffbf00;
}

.error-list li.frequencyErr::before {
  background-color: #bf00ff;
}

.error-list li.durationErr::before {
  background-color: #ff00ff;
}

.error-list li.directionErr::before {
  background-color: #ff00bf;
}

.error-list li.activitiesErr::before {
  background-color: #ff0080;
}

.select-design.is-invalid {
  animation: shake 0.5s;
  border: 2px solid purple !important;
}

.content {
  position: relative;
}

.btn-group .btn {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.diagram-area {
  width: 100%;
  min-width: 800px;
  height: 80vh;
  min-height: 400px;
  /*margin: 0;
  padding: 0;
  border: none black;
  margin: 10px 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: 2px;*/
}

// Business profile Currency select
.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 280px;
  border-radius: 5px;
  border: 1px solid rgba(73, 99, 254, 0.1);
  background-color: #fbfcfc;
  font-size: 11pt;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  max-height: 14em;
  overflow-y: auto;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
  padding: 10px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #4963FE;
  -webkit-transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);

  color: white;
  text-decoration: none;

  &.revert {
    background-color: none;
    transition: 1.5s background-color;
  }
}

/* RatioBar.scss */

/* RatioBar.scss */

.RatioBar {
  width: 95%;
  min-width: 200px;
  margin-left: 0;
  margin-right: 0;
  margin-top: 10px;

  .wrapper {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .bar-container {
    width: 100%;
    height: 50px; /* Making the bar thicker */
    background-color: #FDFFFC;
    border-radius: 25px; /* Making the corners rounder to match thickness */
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    margin-bottom: 20px;
  }

  .bar {
    height: 100%;
    display: flex;
  }

  .bar-section {
    height: 100%;
    transition: width 1s ease-out;
  }

  .bar-section1 {
    background: linear-gradient(45deg, #3FA3CF, #34E39F);
  }

  .bar-section2 {
    background: linear-gradient(45deg, #6295FF, #4963fe);
  }

  .labels {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: -15px;
  }

  .label {
    font-size: 22px;
    font-weight: bold;
  }
}

.treemap-container {
  display: flex;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  height: auto;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  text {
    font-size: 16px !important; /* Increase the font size */
    font-weight: bold; /* Make the text bold */
    margin-right: 5px;
    margin-top: 5px;
  }

  label {
    font-size: 24px;
    font-weight: bold;
    text-anchor: middle;
    fill: #333;
  }  
}

.TreeMap__breadcrumb {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-right: 10px;
  
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.treemap-node {
  stroke: #fff;
  stroke-width: 1px;
}

.treemap-rect {
  fill-opacity: 0.25;
  transition: fill-opacity 0.3s ease;
}

.treemap-rect:hover {
  fill-opacity: 1;
}

.treemap-label {
  font-size: 14px;
  font-weight: bold;
  text-anchor: middle;
  fill: #333;
}

.treemap-tooltip {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px;
  border-radius: 3px;
  font-size: 12px;
}

@media (max-width: 960px) {
  .treemap-container {
    width: 100%;
    padding: 10px;
  }
}

.content-wrapper {
  display: flex;
  align-items: stretch;
}

.text-content {
  flex: 1;
}

.orientation-list,
.classification-list {
  list-style-type: none;
  padding-left: 0; /* Optional: removes default padding */
}

.sidebar {
  position: absolute;
  top: 0;
  right: -300px; /* Start off-screen */
  width: 30%;
  height: 100%;
  border-radius: 15px;
  background-color: #f8f9fa;
  box-shadow: 0 8px 15px 0 rgba(88, 129, 235, 0.6);
  padding: 20px;
  transition: right 1.3s ease; /* Smooth transition */
}

.sidebar.open {
  right: 0; /* Moves into view */
}

.close-btn {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  float: right;
}

.basic-multi-select {
  width: 100%;
  background-color: $background;
}

.dashboard-container {
  display: grid;
  gap: 20px;
  align-items: flex-start;
  width: 100%;
}

.analysis-text-card {
  flex: 1;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin: 20px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.analysis-text-card-header {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
}

.chart-container {
  flex: 1;
  min-width: 300px;
  max-width: 80%;
}

.grid-container {
  flex: 1;
  min-width: 400px;
  max-width: 100%;
  margin: 20px;
}

.cell-high {
  background: #456af5 80%;
  color: white;
}
.cell-medium {
  background: #5d7df6 50%;
  color: white;
}
.cell-low {
  background: #b9c8f9 20%;
  color: black;
}
.cell-very-low {
  background: none !important; /* No background color for very low values */
  color: black;
}
